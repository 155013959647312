import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const NotFoundPage = ( { data } ) => (
  <Layout>
    <SEO
    pageType="website"
    linkTitle={data.contentfulSiteSettings.pageNotFoundTitle}
    pageDescription={data.contentfulSiteSettings.pageNotFoundMessage}
    pageURL={data.site.siteMetadata.url}
    pageTitle={data.contentfulSiteSettings.pageNotFoundTitle}
    linkImage={`https:${data.contentfulSiteSettings.contactPageImage.file.url}`}
    />
    <Wrapper>
    <Message>{data.contentfulSiteSettings.pageNotFoundMessage}</Message>
    </Wrapper>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    contentfulSiteSettings(title: {eq: "Site Settings"}) {
      id
      pageNotFoundTitle
      pageNotFoundMessage
      contactPageImage {
        file {
          url
        }
      }  
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`

const Wrapper = styled.div`
  height: 100vh;
  background-color: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
`

const Message = styled.h1`
  color: #00aeef;
  font-family: 'Montserrat', sans-serif;
  font-size: 8vw;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 450px) {
    font-size: 12vw;
  }
`